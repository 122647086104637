<template>
  <Teleport to="#teleports">
    <Transition name="modal-transition" :duration="500">
      <div
        v-if="open"
        v-bind="attrs"
        ref="modal"
        tabindex="0"
        role="dialog"
        aria-modal="true"
        class="modal fixed pt-10 sm:py-10 inset-0 h-full w-full z-50 grid items-start justify-center overflow-y-auto"
      >
        <div :class="overlayClass" class="modal-overlay bg-overlay fixed inset-0 cursor-pointer backdrop-filter backdrop-saturate-50" @click="close" />

        <div
          tabindex="0"
          :class="contentClass"
          class="modal-content"
        >
          <slot />
          <BaseButton
            type="button"
            look="ghost"
            class="size-12 p-0 z-30 absolute right-2 top-2"
            title="Sulje"
            :aria-label="$t('close')"
            @click="close"
          >
            <XMarkIcon class="size-6" aria-hidden="true" />
          </BaseButton>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  open: {
    type: Boolean,
    required: false,
    default: false
  },
  contentClass: {
    type: [String, Array<string>],
    required: false,
    default: ''
  },
  overlayClass: {
    type: [String, Array<string>],
    required: false,
    default: ''
  }
})

defineOptions({
  inheritAttrs: false
})

const attrs = useAttrs()

const emit = defineEmits(['close'])

const modal = ref<HTMLElement | null>(null)

const { bodyScrollLocked } = useBodyScrollLock()

const close = () => {
  emit('close')
}

watch(() => props.open, async (open) => {
  if (open) {
    bodyScrollLocked.value = true
    await nextTick()
    setTimeout(() => {
      modal.value?.focus()
    }, 500)
  }
  else {
    bodyScrollLocked.value = false
  }
})
</script>

<style lang="scss">
.modal {
  .modal-content {
    @apply rounded-t-xl sm:rounded-xl sm:m-10 dark:border relative bg-surface-raised shadow-lg max-w-screen-md px-4 sm:px-8 lg:px-10 py-8 md:py-12 mt-auto sm:my-auto;
    min-height: 30vh;
    width: clamp(calc(min(100vw, 592px)), 90vw, 768px);
    @screen sm {
      min-height: 0;
    }
  }
}
.modal-transition {
  &-enter,
  &-enter-from,
  &-leave-to {
    .modal-overlay {
      opacity: 0;
    }
    .modal-content {
      opacity: 0;
      transform: translateY(100%)
    }
  }
  &-enter-active,
  &-leave-active {
    overflow-y: hidden !important;
    .modal-overlay {
      transition: opacity 300ms ease;
    }
    .modal-content {
      transition: opacity 300ms ease, transform 500ms ease;
    }
  }
}
</style>
